.DropDown--container {
  position: relative;
}

.DropDown--list {
  position: absolute;
  background-color: white;
  z-index: 1;
  padding: 10px;
  box-shadow: 1px 1px 3px rgba(10, 10, 10, 0.2);
  white-space: nowrap;
}
