.OudatedBrowserModal--alert {
  margin: 0 18px;
  margin-top: 34px;
  margin-bottom: 54px;
}

.OutdatedBrowserModal--buttonsbloc{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
}

@media (max-width: 600px){
  .OudatedBrowserModal--alert{
    margin-bottom: 30px;
  }
  .OutdatedBrowserModal--buttonsbloc{
    align-items: center;
    flex-direction: column-reverse;
  }

  .OutdatedBrowserModal--buttonsbloc button, .OutdatedBrowserModal--buttonsbloc a{
    font-size: 12px;
    margin-top: 10px;
  }
}
