
.red-0 {
  fill: #B71C1C;
  stroke: #B71C1C;
}

.red-1 {
  fill: #C62828;
  stroke: #C62828;
}

.red-2 {
  fill: #D32F2F;
  stroke: #D32F2F;
}

.red-3 {
  fill: #E53935;
  stroke: #E53935;
}

.red-4 {
  fill: #F44336;
  stroke: #F44336;
}

.red-5 {
  fill: #EF5350;
  stroke: #EF5350;
}

.red-6 {
  fill: #E57373;
  stroke: #E57373;
}

.red-7 {
  fill: #EF9A9A;
  stroke: #EF9A9A;
}

.red-8 {
  fill: #FFCDD2;
  stroke: #FFCDD2;
}

.red-9 {
  fill: #FFEBEE;
  stroke: #FFEBEE;
}

.pink-0 {
  fill: #880E4F;
  stroke: #880E4F;
}

.pink-1 {
  fill: #AD1457;
  stroke: #AD1457;
}

.pink-2 {
  fill: #C2185B;
  stroke: #C2185B;
}

.pink-3 {
  fill: #D81B60;
  stroke: #D81B60;
}

.pink-4 {
  fill: #E91E63;
  stroke: #E91E63;
}

.pink-5 {
  fill: #EC407A;
  stroke: #EC407A;
}

.pink-6 {
  fill: #F06292;
  stroke: #F06292;
}

.pink-7 {
  fill: #F48FB1;
  stroke: #F48FB1;
}

.pink-8 {
  fill: #F8BBD0;
  stroke: #F8BBD0;
}

.pink-9 {
  fill: #FCE4EC;
  stroke: #FCE4EC;
}

.purple-0 {
  fill: #4A148C;
  stroke: #4A148C;
}

.purple-1 {
  fill: #6A1B9A;
  stroke: #6A1B9A;
}

.purple-2 {
  fill: #7B1FA2;
  stroke: #7B1FA2;
}

.purple-3 {
  fill: #8E24AA;
  stroke: #8E24AA;
}

.purple-4 {
  fill: #9C27B0;
  stroke: #9C27B0;
}

.purple-5 {
  fill: #AB47BC;
  stroke: #AB47BC;
}

.purple-6 {
  fill: #BA68C8;
  stroke: #BA68C8;
}

.purple-7 {
  fill: #CE93D8;
  stroke: #CE93D8;
}

.purple-8 {
  fill: #E1BEE7;
  stroke: #E1BEE7;
}

.purple-9 {
  fill: #F3E5F5;
  stroke: #F3E5F5;
}

.deep-purple-0 {
  fill: #311B92;
  stroke: #311B92;
}

.deep-purple-1 {
  fill: #4527A0;
  stroke: #4527A0;
}

.deep-purple-2 {
  fill: #512DA8;
  stroke: #512DA8;
}

.deep-purple-3 {
  fill: #5E35B1;
  stroke: #5E35B1;
}

.deep-purple-4 {
  fill: #673AB7;
  stroke: #673AB7;
}

.deep-purple-5 {
  fill: #7E57C2;
  stroke: #7E57C2;
}

.deep-purple-6 {
  fill: #9575CD;
  stroke: #9575CD;
}

.deep-purple-7 {
  fill: #B39DDB;
  stroke: #B39DDB;
}

.deep-purple-8 {
  fill: #D1C4E9;
  stroke: #D1C4E9;
}

.deep-purple-9 {
  fill: #EDE7F6;
  stroke: #EDE7F6;
}

.indigo-0 {
  fill: #1A237E;
  stroke: #1A237E;
}

.indigo-1 {
  fill: #283593;
  stroke: #283593;
}

.indigo-2 {
  fill: #303F9F;
  stroke: #303F9F;
}

.indigo-3 {
  fill: #3949AB;
  stroke: #3949AB;
}

.indigo-4 {
  fill: #3F51B5;
  stroke: #3F51B5;
}

.indigo-5 {
  fill: #5C6BC0;
  stroke: #5C6BC0;
}

.indigo-6 {
  fill: #7986CB;
  stroke: #7986CB;
}

.indigo-7 {
  fill: #9FA8DA;
  stroke: #9FA8DA;
}

.indigo-8 {
  fill: #C5CAE9;
  stroke: #C5CAE9;
}

.indigo-9 {
  fill: #E8EAF6;
  stroke: #E8EAF6;
}

.blue-0 {
  fill: #0D47A1;
  stroke: #0D47A1;
}

.blue-1 {
  fill: #1565C0;
  stroke: #1565C0;
}

.blue-2 {
  fill: #1976D2;
  stroke: #1976D2;
}

.blue-3 {
  fill: #1E88E5;
  stroke: #1E88E5;
}

.blue-4 {
  fill: #2196F3;
  stroke: #2196F3;
}

.blue-5 {
  fill: #42A5F5;
  stroke: #42A5F5;
}

.blue-6 {
  fill: #64B5F6;
  stroke: #64B5F6;
}

.blue-7 {
  fill: #90CAF9;
  stroke: #90CAF9;
}

.blue-8 {
  fill: #BBDEFB;
  stroke: #BBDEFB;
}

.blue-9 {
  fill: #E3F2FD;
  stroke: #E3F2FD;
}

.light-blue-0 {
  fill: #01579B;
  stroke: #01579B;
}

.light-blue-1 {
  fill: #0277BD;
  stroke: #0277BD;
}

.light-blue-2 {
  fill: #0288D1;
  stroke: #0288D1;
}

.light-blue-3 {
  fill: #039BE5;
  stroke: #039BE5;
}

.light-blue-4 {
  fill: #03A9F4;
  stroke: #03A9F4;
}

.light-blue-5 {
  fill: #29B6F6;
  stroke: #29B6F6;
}

.light-blue-6 {
  fill: #4FC3F7;
  stroke: #4FC3F7;
}

.light-blue-7 {
  fill: #81D4FA;
  stroke: #81D4FA;
}

.light-blue-8 {
  fill: #B3E5FC;
  stroke: #B3E5FC;
}

.light-blue-9 {
  fill: #E1F5FE;
  stroke: #E1F5FE;
}

.cyan-0 {
  fill: #006064;
  stroke: #006064;
}

.cyan-1 {
  fill: #00838F;
  stroke: #00838F;
}

.cyan-2 {
  fill: #0097A7;
  stroke: #0097A7;
}

.cyan-3 {
  fill: #00ACC1;
  stroke: #00ACC1;
}

.cyan-4 {
  fill: #00BCD4;
  stroke: #00BCD4;
}

.cyan-5 {
  fill: #26C6DA;
  stroke: #26C6DA;
}

.cyan-6 {
  fill: #4DD0E1;
  stroke: #4DD0E1;
}

.cyan-7 {
  fill: #80DEEA;
  stroke: #80DEEA;
}

.cyan-8 {
  fill: #B2EBF2;
  stroke: #B2EBF2;
}

.cyan-9 {
  fill: #E0F7FA;
  stroke: #E0F7FA;
}

.teal-0 {
  fill: #004D40;
  stroke: #004D40;
}

.teal-1 {
  fill: #00695C;
  stroke: #00695C;
}

.teal-2 {
  fill: #00796B;
  stroke: #00796B;
}

.teal-3 {
  fill: #00897B;
  stroke: #00897B;
}

.teal-4 {
  fill: #009688;
  stroke: #009688;
}

.teal-5 {
  fill: #26A69A;
  stroke: #26A69A;
}

.teal-6 {
  fill: #4DB6AC;
  stroke: #4DB6AC;
}

.teal-7 {
  fill: #80CBC4;
  stroke: #80CBC4;
}

.teal-8 {
  fill: #B2DFDB;
  stroke: #B2DFDB;
}

.teal-9 {
  fill: #E0F2F1;
  stroke: #E0F2F1;
}

.green-0 {
  fill: #1B5E20;
  stroke: #1B5E20;
}

.green-1 {
  fill: #2E7D32;
  stroke: #2E7D32;
}

.green-2 {
  fill: #388E3C;
  stroke: #388E3C;
}

.green-3 {
  fill: #43A047;
  stroke: #43A047;
}

.green-4 {
  fill: #4CAF50;
  stroke: #4CAF50;
}

.green-5 {
  fill: #66BB6A;
  stroke: #66BB6A;
}

.green-6 {
  fill: #81C784;
  stroke: #81C784;
}

.green-7 {
  fill: #A5D6A7;
  stroke: #A5D6A7;
}

.green-8 {
  fill: #C8E6C9;
  stroke: #C8E6C9;
}

.green-9 {
  fill: #E8F5E9;
  stroke: #E8F5E9;
}

.light-green-0 {
  fill: #33691E;
  stroke: #33691E;
}

.light-green-1 {
  fill: #558B2F;
  stroke: #558B2F;
}

.light-green-2 {
  fill: #689F38;
  stroke: #689F38;
}

.light-green-3 {
  fill: #7CB342;
  stroke: #7CB342;
}

.light-green-4 {
  fill: #8BC34A;
  stroke: #8BC34A;
}

.light-green-5 {
  fill: #9CCC65;
  stroke: #9CCC65;
}

.light-green-6 {
  fill: #AED581;
  stroke: #AED581;
}

.light-green-7 {
  fill: #C5E1A5;
  stroke: #C5E1A5;
}

.light-green-8 {
  fill: #DCEDC8;
  stroke: #DCEDC8;
}

.light-green-9 {
  fill: #F1F8E9;
  stroke: #F1F8E9;
}

.lime-0 {
  fill: #827717;
  stroke: #827717;
}

.lime-1 {
  fill: #9E9D24;
  stroke: #9E9D24;
}

.lime-2 {
  fill: #AFB42B;
  stroke: #AFB42B;
}

.lime-3 {
  fill: #C0CA33;
  stroke: #C0CA33;
}

.lime-4 {
  fill: #CDDC39;
  stroke: #CDDC39;
}

.lime-5 {
  fill: #D4E157;
  stroke: #D4E157;
}

.lime-6 {
  fill: #DCE775;
  stroke: #DCE775;
}

.lime-7 {
  fill: #E6EE9C;
  stroke: #E6EE9C;
}

.lime-8 {
  fill: #F0F4C3;
  stroke: #F0F4C3;
}

.lime-9 {
  fill: #F9FBE7;
  stroke: #F9FBE7;
}

.yellow-0 {
  fill: #F57F17;
  stroke: #F57F17;
}

.yellow-1 {
  fill: #F9A825;
  stroke: #F9A825;
}

.yellow-2 {
  fill: #FBC02D;
  stroke: #FBC02D;
}

.yellow-3 {
  fill: #FDD835;
  stroke: #FDD835;
}

.yellow-4 {
  fill: #FFEB3B;
  stroke: #FFEB3B;
}

.yellow-5 {
  fill: #FFEE58;
  stroke: #FFEE58;
}

.yellow-6 {
  fill: #FFF176;
  stroke: #FFF176;
}

.yellow-7 {
  fill: #FFF59D;
  stroke: #FFF59D;
}

.yellow-8 {
  fill: #FFF9C4;
  stroke: #FFF9C4;
}

.yellow-9 {
  fill: #FFFDE7;
  stroke: #FFFDE7;
}

.amber-0 {
  fill: #FF6F00;
  stroke: #FF6F00;
}

.amber-1 {
  fill: #FF8F00;
  stroke: #FF8F00;
}

.amber-2 {
  fill: #FFA000;
  stroke: #FFA000;
}

.amber-3 {
  fill: #FFB300;
  stroke: #FFB300;
}

.amber-4 {
  fill: #FFC107;
  stroke: #FFC107;
}

.amber-5 {
  fill: #FFCA28;
  stroke: #FFCA28;
}

.amber-6 {
  fill: #FFD54F;
  stroke: #FFD54F;
}

.amber-7 {
  fill: #FFE082;
  stroke: #FFE082;
}

.amber-8 {
  fill: #FFECB3;
  stroke: #FFECB3;
}

.amber-9 {
  fill: #FFF8E1;
  stroke: #FFF8E1;
}

.orange-0 {
  fill: #E65100;
  stroke: #E65100;
}

.orange-1 {
  fill: #EF6C00;
  stroke: #EF6C00;
}

.orange-2 {
  fill: #F57C00;
  stroke: #F57C00;
}

.orange-3 {
  fill: #FB8C00;
  stroke: #FB8C00;
}

.orange-4 {
  fill: #FF9800;
  stroke: #FF9800;
}

.orange-5 {
  fill: #FFA726;
  stroke: #FFA726;
}

.orange-6 {
  fill: #FFB74D;
  stroke: #FFB74D;
}

.orange-7 {
  fill: #FFCC80;
  stroke: #FFCC80;
}

.orange-8 {
  fill: #FFE0B2;
  stroke: #FFE0B2;
}

.orange-9 {
  fill: #FFF3E0;
  stroke: #FFF3E0;
}

.deep-orange-0 {
  fill: #BF360C;
  stroke: #BF360C;
}

.deep-orange-1 {
  fill: #D84315;
  stroke: #D84315;
}

.deep-orange-2 {
  fill: #E64A19;
  stroke: #E64A19;
}

.deep-orange-3 {
  fill: #F4511E;
  stroke: #F4511E;
}

.deep-orange-4 {
  fill: #FF5722;
  stroke: #FF5722;
}

.deep-orange-5 {
  fill: #FF7043;
  stroke: #FF7043;
}

.deep-orange-6 {
  fill: #FF8A65;
  stroke: #FF8A65;
}

.deep-orange-7 {
  fill: #FFAB91;
  stroke: #FFAB91;
}

.deep-orange-8 {
  fill: #FFCCBC;
  stroke: #FFCCBC;
}

.deep-orange-9 {
  fill: #FBE9E7;
  stroke: #FBE9E7;
}

.brown-0 {
  fill: #3E2723;
  stroke: #3E2723;
}

.brown-1 {
  fill: #4E342E;
  stroke: #4E342E;
}

.brown-2 {
  fill: #5D4037;
  stroke: #5D4037;
}

.brown-3 {
  fill: #6D4C41;
  stroke: #6D4C41;
}

.brown-4 {
  fill: #795548;
  stroke: #795548;
}

.brown-5 {
  fill: #8D6E63;
  stroke: #8D6E63;
}

.brown-6 {
  fill: #A1887F;
  stroke: #A1887F;
}

.brown-7 {
  fill: #BCAAA4;
  stroke: #BCAAA4;
}

.brown-8 {
  fill: #D7CCC8;
  stroke: #D7CCC8;
}

.brown-9 {
  fill: #EFEBE9;
  stroke: #EFEBE9;
}

.grey-0 {
  fill: #212121;
  stroke: #212121;
}

.grey-1 {
  fill: #424242;
  stroke: #424242;
}

.grey-2 {
  fill: #616161;
  stroke: #616161;
}

.grey-3 {
  fill: #757575;
  stroke: #757575;
}

.grey-4 {
  fill: #9E9E9E;
  stroke: #9E9E9E;
}

.grey-5 {
  fill: #BDBDBD;
  stroke: #BDBDBD;
}

.grey-6 {
  fill: #E0E0E0;
  stroke: #E0E0E0;
}

.grey-7 {
  fill: #EEEEEE;
  stroke: #EEEEEE;
}

.grey-8 {
  fill: #F5F5F5;
  stroke: #F5F5F5;
}

.grey-9 {
  fill: #FAFAFA;
  stroke: #FAFAFA;
}

.blue-grey-0 {
  fill: #263238;
  stroke: #263238;
}

.blue-grey-1 {
  fill: #37474F;
  stroke: #37474F;
}

.blue-grey-2 {
  fill: #455A64;
  stroke: #455A64;
}

.blue-grey-3 {
  fill: #546E7A;
  stroke: #546E7A;
}

.blue-grey-4 {
  fill: #607D8B;
  stroke: #607D8B;
}

.blue-grey-5 {
  fill: #78909C;
  stroke: #78909C;
}

.blue-grey-6 {
  fill: #90A4AE;
  stroke: #90A4AE;
}

.blue-grey-7 {
  fill: #B0BEC5;
  stroke: #B0BEC5;
}

.blue-grey-8 {
  fill: #CFD8DC;
  stroke: #CFD8DC;
}

.blue-grey-9 {
  fill: #ECEFF1;
  stroke: #ECEFF1;
}

.black-0 {
  fill: #000000;
  stroke: #000000;
}

.white-0 {
  fill: #FFFFFF;
  stroke: #FFFFFF;
}
