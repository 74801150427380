
.CssEditor {
  position: relative;
  font-family: "Menlo", "Monaco", "Consolas", "Courier New", monospace;
  font-size: 15px;
  margin: 0px 0px 15px 0px;
  border-radius: 4px;
  font-size: 15px;
  cursor: text;
}

pre {
  white-space: pre;
}

// This is for disable browser outline added when a editable block is focused
code[contentEditable] {
  outline: none;
}