@import "uppy/dist/uppy.min.css";
@import "bootstrap/index.less";
@import "variables.less";
@import "VirtualTables.less";
@import "mixins.less";
@import "material_classes.less";
@import "toggles.less";
@import "sidebar.less";
@import "toast.less";
@import "dashboard.less";
@import "buttons.less";
@import "outdatedBrowserModal.less";
@import "dropdown.less";
@import "actions.less";

@import "font-awesome.min.css";
@import "fort-awesome.min.css";

@import "react-datetime.css";

@font-face {
  font-weight: normal;
  font-family: 'GothamBook';
  src: url('fonts/Gotham/Gotham-Book.otf');
}

@font-face {
  font-weight: normal;
  font-family: 'GothamMedium';
  src: url('fonts/Gotham/Gotham-Medium.otf');
}

.fill {
  height:100%!important;
  // height:100%!important;
  // overflow: scroll;
}

.min-fill {
  min-height:50%!important;

}

.full-width {
  width: 100%;
}

.text-light {
    font-family: 'GothamBook';
    color: #7f7f7f;
}

.text-medium {
    font-family: "GothamMedium"
}

.nowrap {
    white-space: nowrap;
}
.bold {
  font-weight: bold;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

html {
    height: 100%;
}

body {
    height: 100%;
    background-color: @backgroundColor;
    font-weight: lighter;
    font-family: "GothamBook";
}

a:focus {
    outline-style: none;
}

header {
    height: @headerHeight;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // z-index: 1000;

    .navbar-default .navbar-nav img {
        opacity: 0.8;
    }

    .navbar-default .navbar-nav {
        .navbar-icon svg {
            height: 32px;
            width: 32px;
            vertical-align: middle;
            fill: #777;
        }

        .active {
            .navbar-icon svg {
                fill: @blue;
            }
        }

        .navbar-referal {
            .navbar-icon svg {
                fill: #F58C45;
            }

            .text {
                color: #F58C45;
            }
        }

        .navbar-referal.active {
            .navbar-icon svg {
                fill: @blue;
            }

            .text {
                color: @blue;
            }
        }
    }


    .navbar-default .navbar-nav > .active > a,
    .navbar-default .navbar-nav > .active > a:hover,
    .navbar-default .navbar-nav > .active > a:focus {
        img { opacity: 1; }
        color: @blue;
        background-color: transparent;
    }

    .navbar-default .navbar-nav .text {
        display: block;
        margin-top: 5px;
    }
}

.btn-fa {
  position: relative;
  padding-left: 36px;
  .fa {
    position: absolute;
    top: 4px;
    left: 8px;
    font-size: 24px;
  }
}

.prim-btn {
  font-size: 40px !important;
  cursor: pointer;
  // color:@blue;
}
.prim-btn:hover, .prim-btn:focus {
  text-decoration: none !important;
}

.delete-btn {
  cursor: pointer;
  font-size: 40px !important;
  color:@red;
}

.custom-container {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding-top: 90px;
    // padding-top: @headerHeight;
    background-color: @backborder;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;

    .sticky-footer-wrapper {
        height: auto;
        min-height: 100%;
        border: 10px solid @backborder;

        .sticky-footer-content.show-footer {
            padding-bottom: @footerHeight;
        }
    }
}

// .modal-backdrop {
//   z-index:99999999;
// }

// .modal-backdrop {
//   height: 100%
// }
//
// .modal-dialog {
//   height: 100%
// }
//
// .modal-content {
//   height: 100%
// }
// .modal-body {
//   // height: 100%
// }

#footer {
    clear: both;
    position: absolute;
    z-index: 10;
    min-height: @footerHeight;
    margin-top: -@footerHeight;
    width: 100%;

    color: white;
    background-color: @footerColor;

    line-height: @footerHeight - 5px;

    .footer-container {
        text-align: center;

        @media (min-width: @screen-sm-min) {
            padding: 0 90px;
            text-align: left;
        }

        .left-text {
            font-size: 18px;
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
        }

        .right-text {
            @media (min-width: @screen-sm-min) {
                .pull-right;
            }

            .btn {
                font-size: 18px;
            }
        }
    }
}

.btn-dark {
  color: white;
  background-color: #3c3b3b;
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

a.tactill-list-group-item {
    height: 60px;
    border-color: @blue;
    color: @blue;
}

a.tactill-list-group-item:hover {
    color: @blue;
}

.tactill-list-group-item.active {
    color: white;
    background-color: @blue;
}
//
// a.tactill-list-group-item:first-child {
//   background-color: #FFB835;
//   color: white;
// }
//
.text-red {
    color: @red;
}
.text-green {
    color: @green;
}
.text-blue {
    color: @blue;
}

.pictureTable {
    min-height: 20px;

    .icon {
        position: relative;
        border-radius: 7px;
        border: 1px solid rgb(234, 236, 232);
        padding: 2px;
        width: 40px;
        height: 40px;
        background-color: white;
        text-align: center;
        font-size: 16px;
        color: #FFF;

        .img-color {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            background-color: @blue;
        }

        .word-img {
            font-size: 95%;
            position: absolute;
            top: 8px;
            width: 34px;
        }
    }
}

.tactill-tables {
  table-layout: fixed;
}

.tactill-tables > tbody > tr > td {
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tactill-table-head {
  background-color: #f5f5f5;
  border-color: #dddddd;
}

.icon-fontawesome {
    font-family: FontAwesome;
}

.tactill-block {
    // @media (max-width: @screen-md-min) {
    //     padding: 5px;
    // }
    transition-property:height;
    -moz-transition-property: height; /* Firefox 4 */
    -webkit-transition-property:height; /* Safari and Chrome */
    -o-transition-property:height; /* Opera */
    border: 1px solid @borderColor;
    background-color: white;
    padding: 40px;
    margin-bottom: 10px;
}

.tactill-block:last-child {
    margin-bottom: 0;
}

.btn {
    .transition(background-color 0.2s ease-in-out, color 0.2s ease-in-out;);
}

.btn-tomato {
    color: white;
    background-color: tomato;
}

.btn-tomato:hover,
.btn-tomato:focus,
.btn-tomato:active {
    color: white;
    background-color: orangered;
}

.btn-cancel {
    background-color: transparent;
    color: @very-gray-light;
    border: 1px solid #DBDADA;
}

.btn-cancel:hover {
    background-color: white;
    color: black;
}

.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active {
    background-color: @blue;
    border-color: @blue;
}

.border-primary {
  border:solid 1px @blue;
}

.border-warning {
  border:solid 1px @orange;
}


.btn-primary-reverse:enabled {
    background-color: white;
    color: @blue;
}

.btn-primary-transparent {
    background-color: transparent;
    color: @blue;
}

.btn-danger:focus,
.btn-danger:hover,
.btn-danger:active,
.btn-danger.active {
    background-color: @red;
    border-color: @red;
}
.border-danger {
  border:solid 1px @red;
}


.btn-danger-reverse {
    background-color: white;
    color: @red;
}

.btn-add-attributs {
    background-color: transparent;
    width: 100%;
}

.btn-add-attributs:disabled {
  background-color: transparent;
}


.btn-success:focus,
.btn-success:active,
.btn-success.active {
    background-color: @green;
    border-color: @green;
}

.border-success {
  border:solid 1px @green;
}


.btn-success-reverse {
    background-color: white;
    color: @green;
}

.table-sort-link, .table-sort-link:hover {
    text-decoration: none;
    color: #232323;
}

.searchth {
    font-weight: normal;
}

.file-upload {
    position: relative;
    border: 1px dashed @blue;
    color: @blue;
    padding: 20px;
    margin: 10px;

    input.upload {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        height: 100%;
        font-size: 20px;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
    }
}

.image-preview {
  max-height: 40px;
  max-width: 40px;
}

.no-margin {
  margin:0!important;
  padding:0!important;
}

.vertical-align {
  transform: translateY(50%);
}

.file-upload-img {
    position: relative;
    border: 1px dashed @blue;
    color: @blue;
    padding: 7px;
    font-size: 14px;
    padding-top: 10px;

    input.upload {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        height: 100%;
        font-size: 20px;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
    }
}

.loading-top {
  position: fixed;
  top: 0;
  left: 0;
  // z-index: 1001;
  width: 100%;
  height: 4px;
}

.cursor-pointer {
  cursor: pointer;
}

td {
  // border: 1px solid black!important;
}
th {
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: inherit;
  white-space: pre;
}

iframe {
  border: 0;
}
.no-width {
  width:30px;
}

.Select-menu-outer {
  z-index: 99999999;
}

.action-cells {
  width: 50px;
    @media (min-width: @screen-md-min) {
      width:120px
    }
}

.checkout {
  background-color: #333;
  // background-image: url('./images/tactill-bg.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.table > thead > tr > th, .table > thead > td > th {
  cursor: pointer;
}

.boron-modal {
  webkit-transform: translate3d(0,0,0);
}
//
// .modal-open {
//    overflow: hidden;
//    position:relative;
//    height: 100%;
// }

a.link-no-style {
    text-decoration: none;
}


.ct-staff-chart {
  .ct-area {
    fill-opacity:0.6;
  }

  .ct-line {
    stroke-width:1.5px;
  }
  .ct-horizontal {
    display: none;
  }

  .ct-vertical {
    stroke-dasharray:none;
  }

}

.ct-revenue-chart {

  .ct-area {
    fill-opacity:0.6;
  }

  .ct-line {
    stroke-width:0.5px;
  }
  .ct-horizontal {
    display: none;
  }

  .ct-vertical {
    stroke-dasharray:none;
  }

  .ct-serie-revenue-cmp {
    fill: #EDF4FC;
    stroke: #EDF4FC;
  }
  .ct-serie-revenue {
    fill: #4D94E8;
    stroke: #4D94E8;
  }
}

tr.error-td > td {
  border: 3px solid #EC5858!important;
}

.form-group.has-error>.Select > .Select-control {
  border: 1px solid #EC5858;
}

.error-border {
  border: 1px solid #EC5858;

}

.SuperCrud--tools > * {
  margin: 0 4px;
}
.customExportModal *:focus {
    outline: none;
}

.ReactVirtualized__Table > .ReactVirtualized__Table__headerRow {
  // in order to display dropdown in supercrud headers
  overflow-x: initial!important;
  overflow-y: initial!important;
}
.ReactVirtualized__Table__headerColumn > strong > .btn-group > .dropdown-toggle > span {
  font: normal normal normal 14px/1 Wavy;
  content: "\F050";
  font-size: inherit;
  display: contents;
  border-top: 0px;
}
.ReactVirtualized__Table__headerColumn > strong > .btn-group > .dropdown-toggle > span:after {
  content: "\F050";
}
.lwv-indicator {
  border-width: 1px;
  border-style: solid;
  border-color: white;
  width: 9px;
  height: 15px;
}
.lwv-indicator-warning {
  background-color:@red;
}
.lwv-indicator-success {
  background-color:@green;
}
.btn-short-padding {
  padding-left: 3px;
  padding-right: 3px;
}

.order-table .ReactVirtualized__Table__rowColumn.table-col-actions {
  overflow-x: initial!important;
  overflow-y: initial!important;
}
              // .ReactVirtualized__Table__rowColumn.table-col-actions
.order-table .ReactVirtualized__Table__row {
  overflow-x: initial!important;
  overflow-y: initial!important;
}
.order-table .ReactVirtualized__Grid__innerScrollContainer {
  overflow: initial!important;
}

.button-reset {
  outline: 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  background: none;
  color: inherit;
}
