.Action__LineType--create {
  color: #6bcf6b;
}

.Action__LineType--delete {
  color: #ff5050;
}

#wavy-action-tabs > .tab-content,#wavy-action-tabs-pane-viewer {
  height:100%!important;
}
