.smsEditor {
  width: 100%;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -moz-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  -ms-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  font-size: 16px;
  line-height: 1.5;
  color: #555;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.smsEditor__footer {
  border-top: 1px solid black;
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.counter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.counter__circle {
  fill: none;
  stroke-width: 3;
}

.counter__svg {
  width: 40px;
  height: 40px;
}

.mentionSuggestionsEntryText,
.mentionSuggestionsEntryTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mentionSuggestionsEntryTitle {
  font-size: 80%;
  color: #a7a7a7;
}

.counterMessage {
  margin: 0;
  color: rgb(29, 161, 242);
}
.counterMessage.is-red {
  color: red;
}

.counterMessageValue {
  font-weight: bold;
}

.WarningMessage {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.WarningMessage__message {
  color: red;
  font-weight: bold;
  padding-right: 8px;
}

.gray {
  stroke: lightgray;
}
