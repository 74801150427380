
/* itemTypeRepartition */
.repartition-chunk {
  display: inline-block;
  height: 100%;
  border-top: 4px solid white;
  border-bottom: 4px solid white;
}
.repartition-chunk:hover {
  border: 0;
}

.item-type-legend {
  display: flex;
  justify-content: space-around;
  font-size: 20px;
  margin-top: 24px;
}
.item-type-chunks {
  height: 72px;
}

/* itemTypeRepartition */


/* statsBox */
.stats-box {
  border: 1px solid;
  width: 22%;
  height: 200px;
  padding: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.stats-box.stats-box-bg {
  width: 30%;
  height: 250px;
}

.stats-box.stats-box-bg > strong {
  font-size: 21px;
}

.stats-box.stats-box-bg > .average {
  font-size: 48px;
  color: #00cf96;
  margin: 15px;
}

.stats-box.stats-box-bg.stats-box-day > .average {
  font-size: 40px;

}

.stats-box > .description {
  color: #9b9b9b;
}

.stats-box > .average {
  color: #4d94e8;
  font-size: 28px;
}
/* statsBox */


/* Filters */
.dropdown.open > ul.dropdown-menu {
  max-height: 310px;
  overflow: scroll;
}
