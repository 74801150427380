/*!
 * Start Bootstrap - Simple Sidebar (http://startbootstrap.com/)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */

 @sidebarWidth: 220px;
 @xwidth: 60px;

 body {
    overflow-x: hidden;
 }

/* Toggle Styles */

#wrapper {
    padding-left:  @xwidth;
    padding-top:60px;
    // padding-left: 0;
    transition: all 0.3s cubic-bezier(.61,.05,.63,1);
}
.navbar-default > .container-fluid {
  padding:0px;
}

// #wrapper.toggled {
//     padding-left:  @xwidth!important;
//     // padding-left: @sidebarWidth;
//
// }


#sidebar-wrapper {
    box-shadow: -3px 0px 5px 1px #999;
    width: @sidebarWidth;

    z-index: 1000;
    position: fixed;
    // left: @sidebarWidth;
    // width: 0;
    height: 100%;
    margin-left: -@sidebarWidth;
    overflow-y: auto;
    background: #53585F;
    -webkit-transition: all 0.3s cubic-bezier(.61,.05,.63,1);
    -moz-transition: all 0.3s cubic-bezier(.61,.05,.63,1);
    -o-transition: all 0.3s cubic-bezier(.61,.05,.63,1);
    transition: all 0.3s cubic-bezier(.61,.05,.63,1);
}
//
// #wrapper.toggled #sidebar-wrapper {
//     width: @sidebarWidth!important;
//     left: auto;
//
// }

#page-content-wrapper {
    position: absolute;
    padding: 10px 0px;
}

// #wrapper.toggled #page-content-wrapper {
//     position: absolute;
//     margin-right: -@sidebarWidth;
// }

/* Sidebar Styles */

.sidebar-nav {
    position: absolute;
    top: 0;
    width: @sidebarWidth;
    margin: 0;
    padding: 0;
    padding-top: 58px;
    list-style: none;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.sidebar-nav li {
    text-indent: 20px;
    line-height: 40px;
}

.sidebar-nav li a {
    display: block;
    text-decoration: none;
    color: #F0F0F0;
}

.sidebar-nav li a:hover {
    text-decoration: none;
    color: #fff;
    background: rgba(255,255,255,0.2);
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
    text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
    color: #999999;
}

.nav-active {
  background: @green;
}

.sidebar-nav > .sidebar-brand a:hover {
    color: #fff;
    background: none;
}

@media(min-width:768px) {
    #wrapper {
        padding-left: @sidebarWidth;
    }

    #wrapper.toggled {
        padding-left:  @xwidth;
    }

    #sidebar-wrapper {
        width: @sidebarWidth;
        left: auto;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 220px;
    }

    #page-content-wrapper {
        padding: 10px 15px;
        position: relative;
    }

    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-right: 0;
        // padding-right: 60px;
    }
}

.nav-brand {
  display: flex;
  align-items: center;
  padding: 0px 12px;
}

.navbar-default {
  border:0;
  // box-shadow: -32px 4px 5px -6px #999;
  box-shadow: -32px 4px 5px -6px #999;
  background-color: @blue;
}
